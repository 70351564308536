import(/* webpackMode: "eager" */ "__barrel_optimize__?names=Box!=!/app/node_modules/@mui/material/index.js");
import(/* webpackMode: "eager" */ "/app/clients/athena/src/apollo/ApolloWrapper.tsx");
import(/* webpackMode: "eager" */ "/app/clients/athena/src/components/Layout.tsx");
import(/* webpackMode: "eager" */ "/app/clients/athena/src/components/NavigationEvents.tsx");
import(/* webpackMode: "eager" */ "/app/clients/athena/src/components/ThemeRegistry/index.tsx");
import(/* webpackMode: "eager" */ "/app/clients/athena/src/StyledSnackbarProvider.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/@auth0/nextjs-auth0/dist/client/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@workos-inc/authkit-nextjs/dist/esm/authkit-provider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@workos-inc/authkit-nextjs/dist/esm/min-max-button.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next-scroll-restorer/ScrollRestorer.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js")