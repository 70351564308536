'use client';

import { ApolloLink } from '@apollo/client';
import {
  ApolloNextAppProvider,
  NextSSRInMemoryCache,
  NextSSRApolloClient,
  SSRMultipartLink,
} from '@apollo/experimental-nextjs-app-support/ssr';
import { generatePersistedQueryIdsFromManifest } from '@apollo/persisted-query-lists';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';

const PERSISTED_QUERIES_MANIFEST_NAME = 'persisted-query-manifest';
const persistedQueryLink = ['staging', 'production'].includes(process.env.NODE_ENV)
  ? createPersistedQueryLink(
      generatePersistedQueryIdsFromManifest({
        loadManifest: () =>
          import(
            // @ts-ignore
            `../../${PERSISTED_QUERIES_MANIFEST_NAME}.json`
          ),
      }),
    )
  : new ApolloLink((operation, forward) => forward(operation));

// you need to create a component to wrap your app in
export function ApolloWrapper({
  accessToken,
  children,
}: React.PropsWithChildren<{ accessToken?: string | null }>) {
  const authLink = setContext(() => {
    return {
      headers: {
        authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    };
  });

  const makeClient = () => {
    const uploadLink = createUploadLink({
      // this needs to be an absolute url, as relative urls cannot be used in SSR
      uri: `${
        typeof window === 'undefined' ? process.env.API_URL : process.env.NEXT_PUBLIC_BASE_URL
      }/api/graphql`,
      headers: {
        'Apollo-Require-Preflight': 'true',
      },
      // you can disable result caching here if you want to
      // (this does not work if you are rendering your page with `export const dynamic = "force-static"`)
      fetchOptions: { cache: 'no-store' },
      // you can override the default `fetchOptions` on a per query basis
      // via the `context` property on the options passed as a second argument
      // to an Apollo Client data fetching hook, e.g.:
      // const { data } = useSuspenseQuery(MY_QUERY, { context: { fetchOptions: { cache: "force-cache" }}});
    });

    return new NextSSRApolloClient({
      // use the `NextSSRInMemoryCache`, not the normal `InMemoryCache`
      cache: new NextSSRInMemoryCache(),
      link:
        typeof window === 'undefined'
          ? ApolloLink.from([
              // in a SSR environment, if you use multipart features like
              // @defer, you need to decide how to handle these.
              // This strips all interfaces with a `@defer` directive from your queries.
              new SSRMultipartLink({
                stripDefer: true,
              }),
              persistedQueryLink,
              authLink,
              uploadLink,
            ])
          : ApolloLink.from([persistedQueryLink, uploadLink]),
    });
  };

  return <ApolloNextAppProvider makeClient={makeClient}>{children}</ApolloNextAppProvider>;
}
